<template>
  <div class="task-list">
    <div class="top-bar">
      <form action="/">
        <van-search
          v-model="searchText"
          placeholder="请输入任务标题"
          shape="round"
          :show-action="showAction"
          @focus="showAction = true"
          @blur="showAction = false"
          @search="onSearch"
        />
      </form>
      <dl class="options">
        <dt class="title">状态</dt>
        <dd class="btn-wrap">
          <div
            v-for="(item, index) in taskStatusList"
            :key="index"
            :class="{ option: true, active: item.value === taskStatusActive }"
            @click="handleClickOption('taskStatus', item.value)"
          >
            {{ `${item.label}(${taskNum[item.value] > 1000 ? '1000+' : taskNum[item.value] || 0})` }}
          </div>
        </dd>
      </dl>
    </div>
    <van-pull-refresh
      v-if="!searchLoading"
      v-model="refreshLoading"
      success-text="刷新成功"
      class="scroll-wrapper"
      @refresh="onRefresh"
    >
      <div
        v-if="taskNum[taskStatusActive] > 0"
        class="task-list-wrapper"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          @load="onLoad"
        >
          <task-item
            v-for="item in taskList"
            :key="item.userTaskId"
            :task-info="item"
            :user-task-id="item.userTaskId"
            :user-task-id-encrypt="item.userTaskIdEncrypt"
            :task-name="item.taskName"
            :job-end-time="item.jobEndTime"
            :filter-task-type="item.filterTaskType"
            :task-status="item.status"
            :status-desc="item.statusDesc"
            :quality-desc="item.qualityDesc || ''"
            :quality-issue="item.qualityIssue || []"
            :image-num="item.imageNum"
            :unknow-file-num="item.unknowFileNum"
            :video-num="item.videoNum"
            :file-num="item.fileNum"
            :qualified-number="item.qualifiedNumber"
            :need-number="item.needNumber"
            :role-type="roleType"
            :task-detail="item.taskDetail"
            :collectPlanType="collectPlanType"
            @click.native="handleClickTask(item)"
            @on-search="onSearch"
          />
          <div
            slot="finished"
            class="finished-text"
          >
            <p>— — 我是有底线的 — —</p>
            <p>只显示有效期内的任务</p>
          </div>
        </van-list>
      </div>
      <van-empty v-else />
    </van-pull-refresh>
    <van-loading
      v-else
      size="24px"
    >加载中....</van-loading>

    <div class="footer">
      <div class="btn-content" :style="{ justifyContent: roleType === roleTypeMap.COMMON ? 'flex-start' : 'space-between' }">
        <van-popover
          v-if="showAppDownloadBtn && !downloadAppLoading"
          v-model="showPopover"
          :actions="deviceBrands"
          trigger="click"
          placement="top-end"
          @select="onSelectBrand"
        >
          <template #reference>
            <CircleButton>
              <div class="app-download_text">
                <div>App</div>
                <div>下载</div>
              </div>
            </CircleButton>
          </template>
        </van-popover>
        <CircleButton v-else-if="downloadAppLoading" disabled>
          <div class="app-download_text">
            <div>下载中...</div>
          </div>
        </CircleButton>
        <template v-if="roleType === roleTypeMap.COMMON">
          <CircleButton
            :disabled="gettingTask"
            :innerClass="hasNeedSumbitTask ? 'btn_gray' : ''"
            @click="getTask"
          >
            {{ `领取${gettingTask ? '中...' : ''}`}}
          </CircleButton>
        </template>
        <template v-if="roleType === roleTypeMap.SUPPER">
          <van-button
            class="btn-gap"
            type="primary"
            size="small"
            @click="toUserRegister"
          >用户管理</van-button>
          <van-button
            class="btn-gap"
            type="primary"
            size="small"
            :class="{ 'btn_disabled': downloading }"
            @click="downloadApkExcel"
          >
            {{ `${downloading ? '下载中...' : '明细下载'}`}}
          </van-button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant';
import { 
  getUserTaskList, 
  downloadApkExcel, 
  getCrowdSourceTask,
  requestBrands,
  getAppInfo,
 } from "@/apis";
import { roleTypeMap, redirectUrlByAllocationStrategy } from "@/apis/config";
 
import {
  taskStatusList as allTaskStatusList,
  taskStatusEnum,
  VENDOR_MAP,
} from "./config";
import TaskItem from "./task-item.vue";
import { Toast } from "vant";
import { getUrlParams } from "../shared";
import { getCookie } from "@/tools/cookie";
import { invalidateToken } from '@/tools/request';
import { getTokenToObj } from '@/tools/utils';
import deviceDetect from "@/tools/mobile-detect";
import { sentryReportMessage } from "@/tools/sentry";
import CircleButton from "../components/CircleButton.vue";

export default {
  name: "TaskList",
  components: {
    TaskItem,
    CircleButton,
  },
  data() {
    return {
      roleTypeMap,
      searchText: "",
      showAction: false, // 是否在搜索框右侧显示取消按钮
      taskStatusActive: taskStatusEnum.ALL, // 任务状态选中项
      taskList: [], // 任务列表
      total: null, // 任务数量
      loading: false, // 上拉加载的loading
      refreshLoading: false, // 下拉刷新的loading
      searchLoading: true, // 搜索的loading
      finished: false,
      isFirstLoad: true,
      // limit: 15,
      limit: 10000, // 待后端搜索功能ok后，再改回15
      offset: 0,
      taskNum: {}, // 不同状态的任务数量，
      scroll: 0,
      channelCode: '',
      showDownloadButton: false,
      downloading: false,
      roleType: roleTypeMap.COMMON, // 默认普通用户
      collectPlanType: 0,
      gettingTask: false,
      showAppDownloadBtn: deviceDetect.isMobile && (deviceDetect.isAndroid || deviceDetect.isHarmonyOS),
      showPopover: false,
      downloadAppLoading: false,
      deviceBrands: [],
    };
  },
  computed: {
    // 任务状态
    taskStatusList() {
      // 超管-待领取、未提交、已提交
      if(this.roleType === roleTypeMap.SUPPER){
        return allTaskStatusList.filter(item => item.value !== taskStatusEnum.ABANDONED)
      }
      // 代理&普通-未提交、已提交、已放弃
      return allTaskStatusList.filter(item => item.value !== taskStatusEnum.UNCLAIMED);
    },
    hasNeedSumbitTask(){
      return !!this.taskNum[taskStatusEnum.NEED_SUBMIT]
    }
  },
  async activated() {
    document.title = "";
    const $el = document.getElementsByClassName('scroll-wrapper')[0]
    if (this.scrollTop) {
      $el.scrollTop = this.scrollTop
    }
    const urlParams = getUrlParams(this.$route.query) || {};
    await this.getTaskList({ isFirstLoad: true, isRefresh: false });
    if(urlParams.getTask === 'true'){
      delete urlParams.getTask;
      this.$router.push({ urlParams });
      this.getTask();
    }
  },
  beforeRouteLeave(to, from, next) {
    const $el = document.getElementsByClassName('scroll-wrapper')[0];
    if($el){
      this.scrollTop = $el.scrollTop;
    }
    next();
  },
  created(){
    const { roleType, collectPlanType } = getTokenToObj() || {};
    this.roleType = +roleType || roleTypeMap.COMMON;
    this.collectPlanType = +collectPlanType || 0;
    this.taskStatusActive = this.roleType === roleTypeMap.COMMON ? taskStatusEnum.NEED_SUBMIT : taskStatusEnum.ALL;
    if(this.showAppDownloadBtn){
      this.requestBrands();
    }
  },
  mounted() {
    this.channelCode = getCookie("channelCode");
  },
  beforeRouteEnter(to, from, next) {
    const path = to.path;
    redirectUrlByAllocationStrategy(path)
    next()
  },
  methods: {
    async getTaskList({ isFirstLoad = true, isRefresh = false }) {
      // isFirstLoad: true, isRefresh: true, 下拉刷新
      // isFirstLoad: true, isRefresh: false, 搜索
      // isFirstLoad: false, 上拉加载
      if (isFirstLoad) {
        this.offset = 0;
        // 下拉刷新时，需将finished置为true，否则会触发一次额外的上拉加载(bug)
        // 搜索时，则将searchLoading置为true
        if (isRefresh) {
          this.finished = true;
        } else {
          this.searchLoading = true;
        }
      } else {
        this.offset += this.limit;
      }
      const params = {
        taskStatus:
          this.taskStatusActive === taskStatusEnum.ALL
            ? undefined
            : this.taskStatusActive,
        titleKeyword: this.searchText,
        limit: this.limit,
        offset: this.offset,
        backTrackFlag: 1, // 反溯源链路传1
        path: process.env.CHANNEL
      };
      try {
        const res = await getUserTaskList(params);
        if (res && res.code === 0) {
          const { data = [], total, statusCountMap = {}} = res.data || {};
           // 给全部赋值
           statusCountMap[taskStatusEnum.ALL] = total;
          this.taskNum = statusCountMap
          this.total = statusCountMap[this.taskStatusActive];
          this.taskList = isFirstLoad ? data : [...this.taskList, ...data];
          // 以下代码，待后端搜索功能ok后删除
          if (this.searchText !== "") {
            this.taskList = this.taskList.filter((item) =>
              item.taskName.includes(this.searchText)
            );
            this.total = this.taskList.length;
          }
         
        } else {
          if (!isFirstLoad) {
            this.offset -= this.limit;
          }
          if (res.code === 107) {
            invalidateToken()
            if (!this.channelCode) {
              Toast.fail("登录失效，请重新扫码");
            } else {
              Toast.fail(res.msg || "获取任务列表失败");
              setTimeout(() => {
                this.$router.push({
                  path: `/login?channelCode=${this.channelCode}`,
                  replace: true,
                });
              }, 2000);
            }
          } else {
            Toast.fail(res.msg || "获取任务列表失败");
          }
        }
      } catch (e) {
        Toast.fail("获取任务列表失败");
      } finally {
        this.loading = false;
        this.searchLoading = false;
        this.refreshLoading = false;
        this.finished = this.taskList.length >= this.total;
      }
    },
    onSearch() {
      this.getTaskList({ isFirstLoad: true, isRefresh: false });
    },
    onRefresh() {
      this.getTaskList({ isFirstLoad: true, isRefresh: true });
    },
    onLoad() {
      this.getTaskList({ isFirstLoad: false, isRefresh: false });
    },
    async requestBrands(){
      try {
        const response = await requestBrands();
        if(response && response.code === 0){
          this.deviceBrands = (response.data || []).map(item => {
            return { text: item.brand, ...item };
          });
        } else {
          Toast.fail("获取厂商失败，请刷新页面重试！");
        }
      } catch (error) {
        Toast.fail("获取厂商失败，请刷新页面重试！");
      }
    },
    handleClickOption(type, value) {
      if (type === "taskStatus" && this.taskStatusActive !== value) {
        this.taskStatusActive = value;
        this.onSearch();
      }
    },
    handleClickTask(item) {
      if(item.status === taskStatusEnum.ABANDONED){
        return;
      }
      let detailAuth = item.detailAuth; // 详情页权限 无-0 有-1
      if (detailAuth) {
        let detailPageUri = item.detailPageUri;
        this.$router.push({ path: `${detailPageUri}&status=${item.status}` });
      } else {
        Toast.fail("您无权查看任务详情页面");
      }
    },
    async getTask(){
      /**领取任务 */
      if(this.hasNeedSumbitTask){
        Toast.fail('存在未提交的任务，无法领取新任务');
        return 
      }
      if(this.gettingTask){
        return;
      }
      this.gettingTask = true;
      try {
        const res = await getCrowdSourceTask();
        if(res && res.code === 0){
          this.onSearch();
        } else {
          Toast.fail(res.msg || '没有符合条件的任务可领取');
        }
      } catch (error) {
        Toast.fail(error.message || '领取任务失败，请稍后重试');
      } finally {
        this.gettingTask = false;
      }
    },
    async downloadApkExcel(){
      /**下载apk采集进度明细数据 */
      if(this.downloading){
        return;
      }
      this.downloading = true;
      try {
        const res = await downloadApkExcel();
        if(res && res.code === 0 && res.data){
          window.location.href = res.data;
        } else {
          Toast.fail(res.msg || '下载失败，请稍后重试');
        }
      } catch (error) {
        Toast.fail(error.message || '下载失败，请稍后重试');
      } finally {
        this.downloading = false;
      }
    },
    // 后端每个loginType都有登录态，需后端重构
    toUserRegister() {
      this.$router.push({ path: '/userRegister' });
    },
    async onSelectBrand(action){
      const { vendor, ua, os, browser } = deviceDetect.mobileDetect();
      if(
        vendor === VENDOR_MAP.UnKnown ||
        action.brand?.toLowerCase() === vendor ||
        (action.brand === VENDOR_MAP.Other && !this.deviceBrands.map(v => v.brand?.toLowerCase()).includes(vendor))
      ){
        try {
          await Dialog.confirm({
            title: `是否下载${ action.brand }型号的APP`,
            message: "请确认已卸载非本渠道下载的APP"
          });
          this.downloadAPP(action);
          if(vendor === VENDOR_MAP.UnKnown){
            sentryReportMessage("vendor未知", "warning", {
              os,
              browser,
              userAgnet: ua,
              selected: action.brand,
            })
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        Dialog.alert({
          title: "手机型号不匹配，下载失败"
        });
      }
    },
    async downloadAPP(action){
      Toast("正在下载，请稍等...");
      const { brand, project } = action || {};
      this.downloadAppLoading = true;
      try {
        const response = await getAppInfo({ brand, project });
        if(response && response.code === 0){
          const { url } = response.data || {};
          if(url){
            window.location.href = url;
          }
        } else {
          Toast.fail(response.msg || "下载失败，请重试！");
        }
      } catch (error) {
        Toast.fail(error.message || "下载失败，请重试！");
      } finally {
        this.downloadAppLoading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.task-list {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
}
.top-bar {
  flex: none;
  padding-bottom: 15px;
  background-color: #fff;
  dl,
  dd {
    margin: 0;
  }
  .options {
    display: flex;
    align-items: center;
    padding: 0 5px;
    font-size: 12px;
    &:nth-of-type(1) {
      margin-top: 5px;
    }
    &:nth-of-type(2) {
      margin-top: 10px;
    }
    .title {
      display: flex;
      align-self: flex-start;
      justify-content: center;
      width: 40px;
      height: 27px;
      font-size: 14px;
      font-weight: bold;
      line-height: 27px;
    }
    .btn-wrap {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      align-items: flex-start;
      width: 0;
    }
    .option {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 60px;
      height: 27px;
      margin-bottom: 5px;
      margin-left: 10px;
      background-color: #f7f7f7;
      border-radius: 5px;
      &:nth-of-type(1) {
        width: 40px;
      }
      &.active {
        color: #fff;
        background-color: #4badf5;
      }
    }
  }
}
.notice {
  display: flex;
  flex: none;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  padding: 0 15px;
  font-size: 14px;
  color: #d13622;
  background-color: #fbe8d5;
  .content {
    display: flex;
    align-items: center;
    font-size: 13px;
  }
}
.scroll-wrapper {
  flex: 1;
  padding: 10px 15px 15px;
  overflow: auto;
  font-size: 14px;
  background-color: #f5f5f5;
  &.no-notice {
    top: 138px;
  }
  .task-total {
    padding-bottom: 10px;
    color: #767676;
  }
  .task-list-wrapper {
    padding-bottom: 20px;
    overflow-y: auto;
  }

  .finished-text {
    p {
      margin: 0;
      &:nth-of-type(2) {
        font-size: 12px;
      }
    }
  }
}
.van-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
}

.get_task {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 9999;
  font-size: 12px;
  padding: 10px 15px 10px;
  display: flex;
  
  .app-download_text {
      display: flex;
      flex-direction: column;
      line-height: normal;
  }
}
.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  box-shadow: 0 -4px 14px 0 rgba(0,0,0,.04);
  .btn-content {
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: 18px 15px;
  }
  .btn-gap{
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    color: #ffffff;
    border-radius: 48px;
    background: #539cfe;
    line-height: 14px;
    border: none;
  }
}
/deep/ .van-list__finished-text {
  line-height: 0.5rem;
}
</style>


