
export const taskStatusEnum = {
  ALL: -999,
  UNCLAIMED: -1, // 待认领
  NEED_SUBMIT: 0, // 未提交
  ABANDONED: 1, // 已放弃
  SUBMITED: 2, // 已提交
}

export const taskCheckAuthEnum = {
  YES: 1,
  NO: 0,
}
// 众包视觉/非视觉
export const collectPlanTypeMap = {
  NON_VISUAL: 1, // 非视觉
  VISUAL: 2, // 视觉
};

export const taskStatusList = [
  { label: '全部', value: taskStatusEnum.ALL },
  { label: '待领取', value: taskStatusEnum.UNCLAIMED },
  { label: '未提交', value: taskStatusEnum.NEED_SUBMIT},
  { label: '已提交', value: taskStatusEnum.SUBMITED },
  { label: '已放弃', value: taskStatusEnum.ABANDONED },
]

export const VENDOR_MAP = {
  Other: "其他",
  UnKnown: "unknown",
}

export const SOURCE_TYPE = {
  FILE: 1,
  LINK: 2,
};
