<template>
  <div
    class="task-item"
    :style="{ 'border-color': qualityIssue.length > 0 && '#eb3f26' }"
  >
    <div class="box-content">
      <div class="logo-box">
        <img
          src="../images/bag.png"
          class="logo"
        />
      </div>
      <div class="main-cont">
        <div class="top">
          <p 
            class="task-name" 
            :style="{ 'color': taskStatus === taskStatusEnum.ABANDONED && '#939292' }"
          >{{ taskName }}</p>
        </div>
        <div class="middle" :class="statusDescClass">
          <div
            v-if="taskStatus !== taskStatusEnum.NEED_SUBMIT"
            class="uploadNum flex-box"
            @click.stop="jumpToDataListPage"
          >
            <div class="flex1">
              <span
                v-if="videoNum !== 0"
                class="upload-item"
              >视频({{ videoNum }})</span>
              <span
                v-if="imageNum !== 0"
                class="upload-item"
              >图片({{ imageNum }})</span>
              <span
                v-if="fileNum !== 0"
                class="upload-item"
              >文件({{ fileNum }})</span>
              <span
                v-if="unknowFileNum !== 0"
                class="upload-item"
              >未知({{ unknowFileNum }})</span>
            </div>
          </div>
        </div>
        <div
          class="bottom"
          :class="statusDescClass"
        >
          <div class="deadline">
            <div>截止时间：{{ jobEndTime }}</div>
          </div>
          <p class="status-desc">
            <span>{{ statusDesc }}</span>
          </p>
        </div>
        <div class="user-info flex-box">
          <div class="flex1 flex-box flex-vertical-center">
            <span class="font icon-user"></span>
            <span class="user-name">{{ taskInfo.userCode }}</span>
          </div>
          <div 
            v-if="roleTypeMap.COMMON === roleType && taskStatus === taskStatusEnum.NEED_SUBMIT && collectPlanType !== collectPlanTypeMap.NON_VISUAL" 
            class="flex0 abandon_task" 
            @click.stop="abandonTask"
          >
            放弃任务
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="qualityIssue.length > 0"
      class="quality-issue-list"
    >
      <div
        v-for="(item, index) in qualityIssue"
        :key="index"
        class="quality-issue flex-box flex-vertical-center"
      >
        <p
          v-if="item.title !== null"
          class="title flex0"
        >{{ item.title }}</p>
        <p
          class="content flex1"
          :style="{ 'font-weight': item.title !== null && 'bold' }"
        >{{ item.content }}</p>
      </div>
    </div>
    <!-- 众包非视觉放弃任务开始任务 -->
    <div v-if="collectPlanType === collectPlanTypeMap.NON_VISUAL && roleType === roleTypeMap.COMMON" class="footer-buttom">
      <div 
        v-if="taskStatus === taskStatusEnum.NEED_SUBMIT" 
        class="aband_task"
        @click.stop="abandonTask"
      >
        放弃任务
      </div>
      <van-button
        v-if="taskStatus === taskStatusEnum.NEED_SUBMIT || taskStatus === taskStatusEnum.SUBMITED" 
        class="aband_task start_task"
        v-bind="btnConfig"
        :loading="launchLoading"
        @click.stop="launchTask"
      >开始任务</van-button>
    </div>
  </div>
</template>

<script>
import { taskStatusEnum, collectPlanTypeMap } from "./config";
import { abandonCrowdSourceTask } from '@/apis'
import { roleTypeMap } from "@/apis/config";
import { Toast } from "vant";
import { copyText } from "../shared";

export default {
  name: "TaskItem",
  props: {
    userTaskId: {
      type: Number,
      required: true,
    },
    userTaskIdEncrypt: {
      type: String,
      required: true,
    },
    taskName: {
      type: String,
      required: true,
    },
    jobEndTime: {
      type: String,
      required: true,
    },
    filterTaskType: {
      type: Number,
      required: true,
    },
    taskStatus: {
      type: Number,
      required: true,
    },
    statusDesc: {
      type: String,
      required: true,
    },
    qualityDesc: {
      type: String,
      required: true,
    },
    qualityIssue: {
      type: Array,
      required: true,
    },
    videoNum: {
      type: Number,
      default: 0,
    },
    imageNum: {
      type: Number,
      default: 0,
    },
    fileNum: {
      type: Number,
      default: 0,
    },
    unknowFileNum: {
      type: Number,
      default: 0,
    },
    qualifiedNumber: {
      type: Number,
      default: 0,
    },
    needNumber: {
      type: Number,
      default: 0,
    },
    taskInfo: {
      type: Object,
      default: () => {
        return null;
      },
    },
    roleType: {
      type: Number,
      default: 1,
    },
    taskDetail: {
      type: String,
      default: '',
    },
    collectPlanType: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      taskStatusEnum,
      roleTypeMap,
      collectPlanTypeMap,
      launchLoading: false,
      btnConfig: {
        size: "small",
        type: "info",
        round: true,
        loadingText: '复制中...'
      },
    };
  },
  computed: {
    statusDescClass() {
      let statusClass = "";
      if ([taskStatusEnum.NEED_SUBMIT].includes(this.taskStatus)) {
        statusClass = "status_need_submit";
      } else if (
        this.taskStatus === taskStatusEnum.UNCLAIMED
      ) {
        statusClass = "status_unclaimed";
      } else if (
        this.taskStatus === taskStatusEnum.SUBMITED
      ) {
        statusClass = "status_in_quarlity";
      } else if (
        this.taskStatus === taskStatusEnum.ABANDONED
      ) {
        statusClass = "status_abandoned";
      }
      return statusClass;
    },
    canJumpToDataListPage() {
      return [
        taskStatusEnum.SUBMITED,
      ].includes(this.taskStatus)
    },
  },
  methods: {
    async abandonTask(){
      try {
        await abandonCrowdSourceTask({
          userTaskId: this.userTaskIdEncrypt
        })
        this.$emit('on-search');
      } catch (error) {
        Toast.fail(error.message || '放弃任务失败，请重试');
      }
    },
    jumpToDataListPage() {
      if (this.canJumpToDataListPage) {
        this.$router.push({
          path: "/collectDataList",
          query: {
            userTaskId: this.userTaskIdEncrypt, // 加密id
            taskName: this.taskName,
            needCheck: false,
          },
        });
      }
    },
    /**
     * @description 开始任务复制任务信息到剪切板
     */
    launchTask(){
      this.launchLoading = true;
      if(!this.taskDetail){
        Toast.fail('复制失败，请进详情页作业');
        this.launchLoading = false;
        return;
      }
      try {
        copyText(this.taskDetail);
      } catch (error) {
        Toast.fail('复制失败，请进详情页作业');
      } finally{
        this.launchLoading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.task-item {
  margin-bottom: 10px;
  background-color: #fff;
  border: 1px solid #cecdcd;
  border-radius: 7px;
  .box-content {
    display: flex;
    padding: 10px 15px;
  }
  .bottom-tip {
    display: flex;
    align-items: center;
    background: #fceeee;
    color: #eb3223;
    padding: 5px 10px;
    font-size: 14px;
    .invalid-type {
      border: 1px solid #eb3223;
      margin-right: 8px;
      padding: 0px 7px;
    }
    .right-arrow{
      margin-left: auto;
    }
  }
  .top {
    display: flex;
    .task-name {
      padding-left: 10px;
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .deadline {
      padding-left: 10px;
      font-size: 12px;
      color: #939292;
    }
    .status-desc {
      flex: none;
    }
  }
  .middle {
    font-size: 12px;
    padding-left: 10px;
    .uploadNum {
      margin-top: 6px;
      color: #333;
      .upload-item {
        margin-right: 5px;
        color: #3e7bf3;
      }
      .red {
        color: #eb3233;
      }
      .black {
        color: #000;
      }
    }
  }
  .status_need_submit {
    .status-desc {
      color: #eb3223;
    }
  }
  .status_unclaimed {
    .status-desc {
      color: #FFC37E;
    }
  }
  .status_in_quarlity {
    .uploadNum {
      color: #3e7bf3;
    }
    .status-desc {
      color: #3e7bf3;
    }
  }
  .status_abandoned {
    .status-desc {
      color: #939292;
    }
  }
  .quality-issue-list {
    padding: 6px 15px;
    font-size: 12px;
    color: #c7291c;
    background-color: #fceeed;
    border-radius: 7px;
    .quality-issue {
      &:not(:last-child) {
        margin-bottom: 3px;
      }
      .title {
        padding: 0 5px;
        margin-right: 7px;
        border: 1px solid #eb442b;
        max-width: 70px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
p {
  margin: 0;
}
img {
  width: 40px;
  height: 40px;
}
.user-info {
  margin: 5px 0 5px 10px;
  .user-name {
    margin-left: 5px;
    font-size: 12px;
    color: #939292;
  }
  .abandon_task {
    font-size: 12px;
    border: 1px solid #3e7bf3;
    color: #3e7bf3;
  }
}
.footer-buttom {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  font-size: 12px;

  .aband_task {
    display: block;
    padding: 6px 30px;
    border-radius: 6px;
    border: 1px solid #3e7bf3;
    color: #3e7bf3;
  }
  .start_task {
    border: 0px;
    background-color: #1E97D6;
    color: #FFFFFF;
  }
}

@font-face {
  font-family: "icon-font";
  src: url("./assets/iconfont.eot?t=1635409574237");
  src: url("./assets/iconfont.eot?t=1635409574237#iefix")
      format("embedded-opentype"),
    url("./assets/iconfont.woff?t=1635409574237") format("woff"),
    url("./assets/iconfont.ttf?t=1635409574237") format("truetype"),
    url("./assets/iconfont.svg?t=1635409574237#font") format("svg");
}

.font {
  font-family: "icon-font" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-user::before {
  color: #666;
  content: "\e000";
}

.flex-box {
  display: flex;
  &.flex-vertical-center {
    align-items: center;
  }
  .flex0 {
    flex: none;
  }
  .flex1 {
    flex: 1;
  }
}

.main-cont {
  flex: 1;
}
.bg-img {
  width: 40px;
  height: 40px;
  background: left center no-repeat;
  background-size: cover;
}
</style>
